@import '../../../src/styles/_variable.scss';
@import '../../../src/styles/_mixin.scss';

.menuBurgerItem-container {
  opacity: 0;
  animation: 1s appear forwards;
  position:relative;
  display:inline-block!important;
  padding:0!important;
  border-bottom: 1px solid $site-tertiary;
  width: 90%;

  &.prev {
    border-bottom: 2px solid white;
  }

  .menuBurgerItem-item {
    font-family:'Open Sans', sans-serif;
    font-size: 16px;
    padding: 1rem 0;
    margin: 0 0 0 5%;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    animation: 0.5s slideIn forwards;
    display:inline-block;
    width: 70%;
    padding: 18px 0;

    &.prev {
      font-size: 20px;
      margin: 0;
      padding: 10px 0 0 15px;
      width: 100%;
      &:before {
        content: '';
        border: 2px solid transparent;
        width: 8px;
        height: 8px;
        margin: auto;
        display: inline-block;
        transform: rotate(-45deg);
        border-top-color: white;
        border-left-color: white;
        border-right: none;
        border-bottom: none;
        margin-right: 10px;
        margin-bottom: 3px;
      }
    }
  }

  .menuBurgerItem-button {
    display:inline-block;
    width: 40px;
    height: 40px;
    line-height:30px;
    cursor:pointer;
    position:relative;

    &.next:after{
      content: '';
      border: 2px solid transparent;
      width: 8px;
      height: 8px;
      margin: auto;
      position: absolute;
      bottom: 0;
      transform: rotate(-45deg);
    }

    &.next {
      position:absolute;
      height: 100%;
      width: 25%;
      &:after {
        border-right-color: white;
        border-bottom-color: white;
        border-top: none;
        border-left: none;
        top: 7%;
        left: 50%;
      }
    }
  }
}
