@import '../../../src/styles/_variable.scss';
@import '../../../src/styles/_mixin.scss';

.body-container {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 $sm-spacing;
  margin-top: 20px;
  padding: 0 10px 0 15px;

  a {
    text-decoration: none;
    color: $linkColor;
    border-bottom: 2px solid transparent;
    &:hover, &.active {
      border-bottom: solid 2px $link;
    }
  }

  .elevation0 {
    padding-left: 0;
  }
}
