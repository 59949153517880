
@import '../../../src/styles/_variable.scss';
@import '../../../src/styles/_mixin.scss';

.suspectReport-container {
  .suspectWebsite-question {
    padding-top: 22px;
    margin-bottom: 0;
  }
  .ip-summary {
    color: $site-gray!important;
  }
}
