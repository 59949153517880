// $xsm: 360px;
// $sm: 576px;
// $md: 768px;
// $lg: 992px;
// $xl: 1200px;

@mixin mobile-portrait {
  @media (min-width: #{$xsm}) {
    @content;
  }
}
@mixin mobile-wide {
  @media (min-width: #{$sm}) {
    @content;
  }
}
@mixin tablet-portrait {
  @media (min-width: #{$md}) {
    @content;
  }
}
@mixin tablet-wide {
  @media (min-width: #{$lg}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$xl}) {
    @content;
  }
}
