
@import '../../../src/styles/_variable.scss';
@import '../../../src/styles/_mixin.scss';

.backButton-container {
  .backButton-link {
    &:before {
      content:url('./arrow.svg');
      position: relative;
      top:1px;
      left:1px;
      margin-right: 10px;
    }
  }
}
