.button {
  background-color: white;
  padding: 10px 20px;
  border: none;
  border: 1px solid black;
  margin-bottom: 20px;
  width: 100px;
  letter-spacing: 1px;
  cursor:pointer;
  border-radius:3px;
}

.primary-btn {
  @extend .button;
  display: block;
  border: 2px solid $btn-success;
  color: $btn-success;
  font-weight: 550;
  &:hover{
    background-color: $primary-btn--hover;
  }
}
.tertiary-btn {
  @extend .button;
  border: 2px solid $site-gray;
  font-weight: 550;
  color: $site-gray;
  &:hover {
    background-color: $tertiary-btn--hover;
    color: $site-primary;
  }
}
.action-btn {
  display:block;
  @extend .button;
  margin-top: 20px;
  background-color:$btn-success;
  color: white;
  border: 2px solid $site-primary;
  &:hover {
    background-color:$action-btn--hover;
    color: $site-primary;
    border: 2px solid $site-primary;
  }
}
.inactive-btn {
  @extend .button;
  border-color: $site-lightgray;
  color: $site-lightgray;
  display:block;
  cursor:none;
}
