@import '../../../src/styles/_variable.scss';
@import '../../../src/styles/_mixin.scss';

.aboutYou-contaner {
  .aboutYou-heading {
    margin-top:20px;
    margin-bottom: 20px;
  }
  .firstLast-container {
    width: 100%;
    margin-top: -22px;
    & > div, & > fieldset {
      width: 100%;
      @include tablet-portrait {
        width: 50%!important;
        padding-right: 5%!important;
        display: inline-block!important;
      }
    }

  }
}
