.panel-container {
  display:table;
  padding-left:0!important;
  width: 100%;

  li {
    list-style-type: none!important;
  }
  span {
    display:table-cell;
    width: 20%;
  }
  .panel-header {
    display: table-row!important;
    span {
      font-weight: 400;
      font-size: 80%;
      padding: 6px;
      padding-right: 10px;
      text-align: center;
      border: 1px solid white;
      border-bottom: 0;
      background-color: $site-primary;
      color: white;
    }
  }
  .panel-body {
    display: table-row-group!important;
    background-color: whitesmoke;

    .panel-link {
      border-bottom: 0!important;
      width: 100%;
      height: 100%;
      display: table-row!important;

      span {
        font-size: 80%;
        padding: 10px 0;
        text-align: center;
        display:table-cell!important;
        width: 23%!important;
        border:1px solid white;
      }
    }
    &:hover {
      background-color: #D7EBF0;
      cursor:pointer;
    }
  }
}
