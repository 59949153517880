@import './_variable';

html {
  background-color: white;
}

body {
  margin: 0;
  font-family: $fontFamily-system;
  font-family: $fontFamily-custom;
  font-size: 18px;
  font-weight: 300;
  color:$black;
  text-size-adjust: 100%;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  &:before, &:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.half-area {
  & > div, & > fieldset {
    width: 100%;
    @include tablet-portrait {
      width: 50%!important;
      padding-right: 5%!important;
      display: inline-block!important;
    }
  }
}

.half-container {
  width: 100%;

  @include tablet-portrait {
    width: 47%;
    margin-right: 20px;
    float: left;
  }
}

.readOnly{
  color: #757575;
  margin: $md-spacing 0;
}

.special-h1 {
  font-size:40px;
}


.form-control {
  h1, h2 {
    color: darken($link, 10%);
    font-weight: normal;
    font-family: $fontFamily-custom;
  }
  h1 {
    font-size: $h1-desktop*0.8;
  }
  h2 {
    font-size: $h2-desktop*0.8;
    margin-bottom: 0;
    margin-top: 28px;
  }
  h3 {
    font-size: $h3-desktop*0.8;
    color: darken($link, 10%);
  }
  h4, span {
    font-size: $h4-desktop*0.8;
  }

  @include tablet-portrait {
    h1 {
      font-size: $h1-desktop;
    }
    h2 {
      font-size: $h2-desktop;
      margin-bottom: 0;
      margin-top: 15px;
    }
    h3 {
      font-size: $h3-desktop;
      font-weight: normal;
    }
    h4 {
      font-size: $h4-desktop;
    }
    span,p {
      font-size: $h5-desktop;
      line-height: 21px;
    }
  }
  legend {
    padding-top: 10px;
    padding-bottom: 3px;
    font-weight: 500;
  }
  .button-group {
    display: block;
    margin-top: 20px;
    height: 40px;
    button {
      float: right;
    }
  }
}

a {
  &:focus {
    border: 1px solid #0496A8;
  }
}

label {
  font-family: $fontFamily-custom;
}

.button-group {
  &.auto {
    button {
      width: auto!important;
    }
  }
    button {
    margin-left: 20px;
  }
}

.variantButton-container {
  margin-top: 20px;
}

.slight-bold {
  font-weight: 550;
}

.mandatory-text {
  margin-bottom: 8px!important;
}

.no-gutterTop {
  margin-top: 0!important;
}

.no-gutterBottom {
  margin-bottom: 0!important;
}

.noRecordMessage-heading {
  margin-bottom: 0;
  margin-top:0;
}

.leftAligned-question {
  margin-top: 15px!important;
  margin-bottom: 10px;
  margin-left:-14px;
}

.fullWidth-input {
  width: 95%;
}
