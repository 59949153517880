@import '../../../src/styles/_variable.scss';
@import '../../../src/styles/_mixin.scss';

.header-main {
  .header-container {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 $sm-spacing;
    color: $banner-anchor;
    @include mobile-portrait {
      padding: 0 $md-spacing;
    }

    .top-nav {
      display: none;
      @include tablet-portrait {
        display: block;
      }
    }
    ul {
      @include tablet-portrait {
        padding-left: 0;
        margin: 0;
        border-bottom: solid 1px $site-underline;
        height: 56px;
      }
      li {
        display: inline-block;
        padding: 20px 0;
        margin-right: 23px;
        height: 56px;
        text-align: -webkit-match-parent;
        cursor:pointer;
        &.right {
          float: right;
        }
        &:hover, &.active {
          border-bottom: solid 3px $link;
        }
        .bubble {
          border: 1px solid #162B4C;
          border-radius: 10px;
          padding: 0 4px;
          font-size: small;
          position: relative;
          top: -8px;
          max-width: none;
          line-height: 32px;
          margin-top: 80px;
          right: -3px;
        }
        a, span {
          text-decoration: none;
          color: $linkColor;
        }
      }
    }
    .logo-container {
      display: inline-block;
      width: 100%;
      line-height:10px;
      h1 {
        display: inline;
      }
      img {
        margin: 10px 0;
        max-height: 60px;
        max-width: 100%;
        padding: 0;
        @include tablet-portrait {
          padding-top: 10px;
          max-height: 90px;
          max-width: 350px;
          margin-bottom: 10px;
        }
      }
      .logo-asd {
        float:left;
        width: 50%;
        margin-left: -10px;
      }
      .logo-cyber {
        float:right;
        width: 50%;
        text-align: right;
        @include tablet-portrait {
          width: auto;
        }
      }
    }
  }
  .header-secondaryNav {
    background-color: $banner-bg;
    font-family: $fontFamily-custom;
    position: relative;
    ul {
      border: none;
      li {
        a,span {
          color: $banner-anchor!important;
          font-weight: 700!important;
          font-size: $banner-nav*0.8;
          @include tablet-portrait {
            width: auto;
            font-size: $banner-nav;
          }
        }
        &:hover{
          border-bottom: solid 3px white;
        }
      }
    }
    .mobile-menu {
      position: absolute;
      top: 25px;
      right: 10px;
      height: 20px;
      width: 25px;
      z-index: 5;
      color: white;
      @include mobile-portrait {
        right: 30px;
      }
    }
    .MenuBurgerItem-container {
      padding-left: 0;
    }
    .mobileOnly {
      @include tablet-portrait {
        display: none;
      }
    }
    .header-subMenu {
      display: none;
      @include tablet-portrait {
        display: inline-block;
      }
    }
    h1 {
      font-weight: 800;
      margin: 20px 0;
      display:inline-block;
      font-size: $h1-desktop*0.8;
      @include tablet-portrait {
        margin: 30px 40px 0 0;
        width: 100%;
        font-size: $h1-desktop;
        margin-bottom: 0;
      }
      @include tablet-wide {
        font-size: $h1-desktop;
        margin: 20px 0;
        width: 40%;
      }
    }
    nav {
      display: none;
      @include tablet-portrait {
        display:inline-block;
        width: 100%;
        text-align:left;
      }
      @include tablet-wide {
        text-align:right;
        width: 60%;
      }
    }
  }
}
