/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * mr-eaves-xl-modern:
 *   - http://typekit.com/eulas/00000000000000003b9adbcf
 *   - http://typekit.com/eulas/00000000000000003b9adbd0
 *   - http://typekit.com/eulas/00000000000000003b9adbd8
 *   - http://typekit.com/eulas/00000000000000003b9adbda
 *
 * © 2009-2018 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2018-08-13 05:54:59 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=ufp1ylv&ht=tk&f=28422.28423.28431.28433&a=6089860&app=typekit&e=css");

@font-face {
font-family:"mr-eaves-xl-modern";
src:url("https://use.typekit.net/af/4fe0eb/00000000000000003b9adbcf/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/4fe0eb/00000000000000003b9adbcf/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/4fe0eb/00000000000000003b9adbcf/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("opentype");
font-style:normal;font-weight:800;
}

@font-face {
font-family:"mr-eaves-xl-modern";
src:url("https://use.typekit.net/af/332c60/00000000000000003b9adbd0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/332c60/00000000000000003b9adbd0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/332c60/00000000000000003b9adbd0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-style:normal;font-weight:400;
}

@font-face {
font-family:"mr-eaves-xl-modern";
src:url("https://use.typekit.net/af/eaf692/00000000000000003b9adbd8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/eaf692/00000000000000003b9adbd8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/eaf692/00000000000000003b9adbd8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-style:normal;font-weight:700;
}

@font-face {
font-family:"mr-eaves-xl-modern";
src:url("https://use.typekit.net/af/c93bdc/00000000000000003b9adbda/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/c93bdc/00000000000000003b9adbda/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/c93bdc/00000000000000003b9adbda/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
font-style:normal;font-weight:300;
}

.tk-eaves,.tk-mr-eaves-xl-modern { font-family: "mr-eaves-xl-modern",sans-serif; }
