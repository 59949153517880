$sm-spacing: 5px;
$md-spacing: 15px;
$lg-spacing: 25px;

$xsm: 360px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$h1-desktop:30px;
$h2-desktop:26px;
$h3-desktop:24px;
$h4-desktop:22px;
$h5-desktop:18px;
$h6-desktop:15px;
$banner-nav:$h5-desktop;


$error:#E04964;
$success:#80C58C;
$info:#4151A3;
$warning:#F58146;


// site-black
$site-primary:#162B4C;
// site-main
$site-secondary:#0496A8;
$site-tertiary:#00aec7;

$site-fourth:#038290;
$site-lightgray:#C0C0C2;
$site-gray:#62647E;

$action-btn--hover: #07B1CE;
$primary-btn--hover: #80DDEB;
$tertiary-btn--hover: #DEE3E4;

$banner-bg:$site-fourth;
$banner-anchor:white;
$btn-success:$site-fourth;

$bg: #FBFBFB;
$site-bg: #FAFAFA;
$border: #CECECE;
$line: #9B9B9B;
$extra-info: #62647E;
$info-text: #162B4C;
$close-hover: #CD2026;
$alert-major--hover: #E04964;
$alert-major--hover: #DC6830;
$alert-info--hover: #00b3c8;
$site-underline:#eae8ea;
$header-bg: #0096A8;
$headerTop-bg: #172B4C;
$headerSignOut--hover: #dccd2f;
$black:#162B4C;
$gray:#3b3b3b;
$light-gray: #DDDDDD;
$step--inactive: $alert-info--hover;
$step--active:$border;
$step--hover:$alert-major--hover;
$linkColor:$info-text;

$link: $alert-info--hover;

$border-gray: darken($light-gray,30%);

$fontFamily-custom: "mr-eaves-xl-modern", "roboto", "helvetica", sans-serif;
$fontFamily-system: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;;
