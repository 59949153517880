
@import '../../../src/styles/_variable.scss';
@import '../../../src/styles/_mixin.scss';

.refDataSelector-container {
  position: relative;
  flex-grow: 1;
  vertical-align: top;
}

.noGutter {
  margin: 0;
  padding:0;
}
