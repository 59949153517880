@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://p.typekit.net/p.css?s=1&k=ufp1ylv&ht=tk&f=28422.28423.28431.28433&a=6089860&app=typekit&e=css);
@-webkit-keyframes appear {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes appear {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateX(-2%);
            transform: translateX(-2%); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes slideIn {
  0% {
    -webkit-transform: translateX(-2%);
            transform: translateX(-2%); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes shrink {
  0% {
    width: 95%; }
  100% {
    width: 90%; } }

@keyframes shrink {
  0% {
    width: 95%; }
  100% {
    width: 90%; } }

.menuBurgerItem-container {
  opacity: 0;
  -webkit-animation: 1s appear forwards;
          animation: 1s appear forwards;
  position: relative;
  display: inline-block !important;
  padding: 0 !important;
  border-bottom: 1px solid #00aec7;
  width: 90%; }
  .menuBurgerItem-container.prev {
    border-bottom: 2px solid white; }
  .menuBurgerItem-container .menuBurgerItem-item {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    padding: 1rem 0;
    margin: 0 0 0 5%;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    -webkit-animation: 0.5s slideIn forwards;
            animation: 0.5s slideIn forwards;
    display: inline-block;
    width: 70%;
    padding: 18px 0; }
    .menuBurgerItem-container .menuBurgerItem-item.prev {
      font-size: 20px;
      margin: 0;
      padding: 10px 0 0 15px;
      width: 100%; }
      .menuBurgerItem-container .menuBurgerItem-item.prev:before {
        content: '';
        border: 2px solid transparent;
        width: 8px;
        height: 8px;
        margin: auto;
        display: inline-block;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        border-top-color: white;
        border-left-color: white;
        border-right: none;
        border-bottom: none;
        margin-right: 10px;
        margin-bottom: 3px; }
  .menuBurgerItem-container .menuBurgerItem-button {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 30px;
    cursor: pointer;
    position: relative; }
    .menuBurgerItem-container .menuBurgerItem-button.next:after {
      content: '';
      border: 2px solid transparent;
      width: 8px;
      height: 8px;
      margin: auto;
      position: absolute;
      bottom: 0;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
    .menuBurgerItem-container .menuBurgerItem-button.next {
      position: absolute;
      height: 100%;
      width: 25%; }
      .menuBurgerItem-container .menuBurgerItem-button.next:after {
        border-right-color: white;
        border-bottom-color: white;
        border-top: none;
        border-left: none;
        top: 7%;
        left: 50%; }

.menuBurgerWrapper-container {
  padding-bottom: 25px;
  padding-left: 0;
  margin-top: 0;
  display: inline-block;
  width: 100%; }

.header-main .header-container {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 5px;
  color: white; }
  @media (min-width: 360px) {
    .header-main .header-container {
      padding: 0 15px; } }
  .header-main .header-container .top-nav {
    display: none; }
    @media (min-width: 768px) {
      .header-main .header-container .top-nav {
        display: block; } }
  @media (min-width: 768px) {
    .header-main .header-container ul {
      padding-left: 0;
      margin: 0;
      border-bottom: solid 1px #eae8ea;
      height: 56px; } }
  .header-main .header-container ul li {
    display: inline-block;
    padding: 20px 0;
    margin-right: 23px;
    height: 56px;
    text-align: -webkit-match-parent;
    cursor: pointer; }
    .header-main .header-container ul li.right {
      float: right; }
    .header-main .header-container ul li:hover, .header-main .header-container ul li.active {
      border-bottom: solid 3px #00b3c8; }
    .header-main .header-container ul li .bubble {
      border: 1px solid #162B4C;
      border-radius: 10px;
      padding: 0 4px;
      font-size: small;
      position: relative;
      top: -8px;
      max-width: none;
      line-height: 32px;
      margin-top: 80px;
      right: -3px; }
    .header-main .header-container ul li a, .header-main .header-container ul li span {
      text-decoration: none;
      color: #162B4C; }
  .header-main .header-container .logo-container {
    display: inline-block;
    width: 100%;
    line-height: 10px; }
    .header-main .header-container .logo-container h1 {
      display: inline; }
    .header-main .header-container .logo-container img {
      margin: 10px 0;
      max-height: 60px;
      max-width: 100%;
      padding: 0; }
      @media (min-width: 768px) {
        .header-main .header-container .logo-container img {
          padding-top: 10px;
          max-height: 90px;
          max-width: 350px;
          margin-bottom: 10px; } }
    .header-main .header-container .logo-container .logo-asd {
      float: left;
      width: 50%;
      margin-left: -10px; }
    .header-main .header-container .logo-container .logo-cyber {
      float: right;
      width: 50%;
      text-align: right; }
      @media (min-width: 768px) {
        .header-main .header-container .logo-container .logo-cyber {
          width: auto; } }

.header-main .header-secondaryNav {
  background-color: #038290;
  font-family: "mr-eaves-xl-modern", "roboto", "helvetica", sans-serif;
  position: relative; }
  .header-main .header-secondaryNav ul {
    border: none; }
    .header-main .header-secondaryNav ul li a, .header-main .header-secondaryNav ul li span {
      color: white !important;
      font-weight: 700 !important;
      font-size: 14.4px; }
      @media (min-width: 768px) {
        .header-main .header-secondaryNav ul li a, .header-main .header-secondaryNav ul li span {
          width: auto;
          font-size: 18px; } }
    .header-main .header-secondaryNav ul li:hover {
      border-bottom: solid 3px white; }
  .header-main .header-secondaryNav .mobile-menu {
    position: absolute;
    top: 25px;
    right: 10px;
    height: 20px;
    width: 25px;
    z-index: 5;
    color: white; }
    @media (min-width: 360px) {
      .header-main .header-secondaryNav .mobile-menu {
        right: 30px; } }
  .header-main .header-secondaryNav .MenuBurgerItem-container {
    padding-left: 0; }
  @media (min-width: 768px) {
    .header-main .header-secondaryNav .mobileOnly {
      display: none; } }
  .header-main .header-secondaryNav .header-subMenu {
    display: none; }
    @media (min-width: 768px) {
      .header-main .header-secondaryNav .header-subMenu {
        display: inline-block; } }
  .header-main .header-secondaryNav h1 {
    font-weight: 800;
    margin: 20px 0;
    display: inline-block;
    font-size: 24px; }
    @media (min-width: 768px) {
      .header-main .header-secondaryNav h1 {
        margin: 30px 40px 0 0;
        width: 100%;
        font-size: 30px;
        margin-bottom: 0; } }
    @media (min-width: 992px) {
      .header-main .header-secondaryNav h1 {
        font-size: 30px;
        margin: 20px 0;
        width: 40%; } }
  .header-main .header-secondaryNav nav {
    display: none; }
    @media (min-width: 768px) {
      .header-main .header-secondaryNav nav {
        display: inline-block;
        width: 100%;
        text-align: left; } }
    @media (min-width: 992px) {
      .header-main .header-secondaryNav nav {
        text-align: right;
        width: 60%; } }

@charset "UTF-8";
html {
  background-color: white; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-family: "mr-eaves-xl-modern", "roboto", "helvetica", sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: #162B4C;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%; }

* {
  box-sizing: border-box; }
  *:before, *:after {
    box-sizing: border-box; }

.half-area > div, .half-area > fieldset {
  width: 100%; }
  @media (min-width: 768px) {
    .half-area > div, .half-area > fieldset {
      width: 50% !important;
      padding-right: 5% !important;
      display: inline-block !important; } }

.half-container {
  width: 100%; }
  @media (min-width: 768px) {
    .half-container {
      width: 47%;
      margin-right: 20px;
      float: left; } }

.readOnly {
  color: #757575;
  margin: 15px 0; }

.special-h1 {
  font-size: 40px; }

.form-control h1, .form-control h2 {
  color: #008595;
  font-weight: normal;
  font-family: "mr-eaves-xl-modern", "roboto", "helvetica", sans-serif; }

.form-control h1 {
  font-size: 24px; }

.form-control h2 {
  font-size: 20.8px;
  margin-bottom: 0;
  margin-top: 28px; }

.form-control h3 {
  font-size: 19.2px;
  color: #008595; }

.form-control h4, .form-control span {
  font-size: 17.6px; }

@media (min-width: 768px) {
  .form-control h1 {
    font-size: 30px; }
  .form-control h2 {
    font-size: 26px;
    margin-bottom: 0;
    margin-top: 15px; }
  .form-control h3 {
    font-size: 24px;
    font-weight: normal; }
  .form-control h4 {
    font-size: 22px; }
  .form-control span, .form-control p {
    font-size: 18px;
    line-height: 21px; } }

.form-control legend {
  padding-top: 10px;
  padding-bottom: 3px;
  font-weight: 500; }

.form-control .button-group {
  display: block;
  margin-top: 20px;
  height: 40px; }
  .form-control .button-group button {
    float: right; }

a:focus {
  border: 1px solid #0496A8; }

label {
  font-family: "mr-eaves-xl-modern", "roboto", "helvetica", sans-serif; }

.button-group.auto button {
  width: auto !important; }

.button-group button {
  margin-left: 20px; }

.variantButton-container {
  margin-top: 20px; }

.slight-bold {
  font-weight: 550; }

.mandatory-text {
  margin-bottom: 8px !important; }

.no-gutterTop {
  margin-top: 0 !important; }

.no-gutterBottom {
  margin-bottom: 0 !important; }

.noRecordMessage-heading {
  margin-bottom: 0;
  margin-top: 0; }

.leftAligned-question {
  margin-top: 15px !important;
  margin-bottom: 10px;
  margin-left: -14px; }

.fullWidth-input {
  width: 95%; }

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * mr-eaves-xl-modern:
 *   - http://typekit.com/eulas/00000000000000003b9adbcf
 *   - http://typekit.com/eulas/00000000000000003b9adbd0
 *   - http://typekit.com/eulas/00000000000000003b9adbd8
 *   - http://typekit.com/eulas/00000000000000003b9adbda
 *
 * © 2009-2018 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2018-08-13 05:54:59 UTC"}*/
@font-face {
  font-family: "mr-eaves-xl-modern";
  src: url("https://use.typekit.net/af/4fe0eb/00000000000000003b9adbcf/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff2"), url("https://use.typekit.net/af/4fe0eb/00000000000000003b9adbcf/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff"), url("https://use.typekit.net/af/4fe0eb/00000000000000003b9adbcf/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("opentype");
  font-style: normal;
  font-weight: 800; }

@font-face {
  font-family: "mr-eaves-xl-modern";
  src: url("https://use.typekit.net/af/332c60/00000000000000003b9adbd0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/332c60/00000000000000003b9adbd0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/332c60/00000000000000003b9adbd0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "mr-eaves-xl-modern";
  src: url("https://use.typekit.net/af/eaf692/00000000000000003b9adbd8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/eaf692/00000000000000003b9adbd8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/eaf692/00000000000000003b9adbd8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: "mr-eaves-xl-modern";
  src: url("https://use.typekit.net/af/c93bdc/00000000000000003b9adbda/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/c93bdc/00000000000000003b9adbda/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/c93bdc/00000000000000003b9adbda/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-style: normal;
  font-weight: 300; }

.tk-eaves, .tk-mr-eaves-xl-modern {
  font-family: "mr-eaves-xl-modern",sans-serif; }

.row {
  margin-left: -12px;
  margin-right: -12px;
  display: flex;
  flex-wrap: wrap; }

/**
 * Borrowed from uikit
 * https://github.com/govau/uikit/blob/1d26de4ec1d4838bfe716555a177029e26c3d0c8/packages/skip-link/src/sass/_module.scss
 */
.uikit-skip-link {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #333333;
  line-height: 1.6; }

.uikit-skip-link__link {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background-color: #ffffff; }
  .uikit-skip-link__link:active, .uikit-skip-link__link:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: absolute;
    width: auto;
    font-size: 21px;
    top: uikit-space(0.5);
    left: uikit-space(0.5);
    padding: uikit-space(0.25) uikit-space(0.5);
    color: #333333;
    background-color: #ffffff;
    text-decoration: none;
    z-index: 1; }

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.is-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  background-color: #ffffff !important;
  color: #333333 !important; }

.hidden {
  display: none; }

.button, .primary-btn, .tertiary-btn, .action-btn, .inactive-btn {
  background-color: white;
  padding: 10px 20px;
  border: none;
  border: 1px solid black;
  margin-bottom: 20px;
  width: 100px;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 3px; }

.primary-btn {
  display: block;
  border: 2px solid #038290;
  color: #038290;
  font-weight: 550; }
  .primary-btn:hover {
    background-color: #80DDEB; }

.tertiary-btn {
  border: 2px solid #62647E;
  font-weight: 550;
  color: #62647E; }
  .tertiary-btn:hover {
    background-color: #DEE3E4;
    color: #162B4C; }

.action-btn {
  display: block;
  margin-top: 20px;
  background-color: #038290;
  color: white;
  border: 2px solid #162B4C; }
  .action-btn:hover {
    background-color: #07B1CE;
    color: #162B4C;
    border: 2px solid #162B4C; }

.inactive-btn {
  border-color: #C0C0C2;
  color: #C0C0C2;
  display: block;
  cursor: none; }

.panel-container {
  display: table;
  padding-left: 0 !important;
  width: 100%; }
  .panel-container li {
    list-style-type: none !important; }
  .panel-container span {
    display: table-cell;
    width: 20%; }
  .panel-container .panel-header {
    display: table-row !important; }
    .panel-container .panel-header span {
      font-weight: 400;
      font-size: 80%;
      padding: 6px;
      padding-right: 10px;
      text-align: center;
      border: 1px solid white;
      border-bottom: 0;
      background-color: #162B4C;
      color: white; }
  .panel-container .panel-body {
    display: table-row-group !important;
    background-color: whitesmoke; }
    .panel-container .panel-body .panel-link {
      border-bottom: 0 !important;
      width: 100%;
      height: 100%;
      display: table-row !important; }
      .panel-container .panel-body .panel-link span {
        font-size: 80%;
        padding: 10px 0;
        text-align: center;
        display: table-cell !important;
        width: 23% !important;
        border: 1px solid white; }
    .panel-container .panel-body:hover {
      background-color: #D7EBF0;
      cursor: pointer; }

.textfield-container {
  margin-top: 10px;
  margin-bottom: 10px;
  vertical-align: top;
  width: 100%; }

.refDataSelector-container {
  position: relative;
  flex-grow: 1;
  vertical-align: top; }

.noGutter {
  margin: 0;
  padding: 0; }

@media (min-width: 768px) {
  .address-container {
    width: 95%; } }

.address-container .manualAddress {
  font-size: 18px;
  margin-left: 15px;
  display: inline-block;
  text-decoration: none; }

.address-container .enterManually-container.half-area label {
  width: 100%; }
  .address-container .enterManually-container.half-area label + div {
    width: 100%; }

.backButton-container .backButton-link:before {
  content: url(../../static/media/arrow.15a5b6e1.svg);
  position: relative;
  top: 1px;
  left: 1px;
  margin-right: 10px; }

.businessDetail-container .businessName-container {
  margin: 5px 0 10px 0 !important; }


.intro-container .intro-link {
  padding-bottom: 20px;
  color: red; }

.aboutYou-contaner .aboutYou-heading {
  margin-top: 20px;
  margin-bottom: 20px; }

.aboutYou-contaner .firstLast-container {
  width: 100%;
  margin-top: -22px; }
  .aboutYou-contaner .firstLast-container > div, .aboutYou-contaner .firstLast-container > fieldset {
    width: 100%; }
    @media (min-width: 768px) {
      .aboutYou-contaner .firstLast-container > div, .aboutYou-contaner .firstLast-container > fieldset {
        width: 50% !important;
        padding-right: 5% !important;
        display: inline-block !important; } }






.suspectReport-container .suspectWebsite-question {
  padding-top: 22px;
  margin-bottom: 0; }

.suspectReport-container .ip-summary {
  color: #62647E !important; }


.submit-container h4 {
  font-size: 22px;
  font-size: 500;
  margin-bottom: 15px; }
  .submit-container h4 a {
    border-bottom: solid 2px #00b3c8; }

.submit-container .summary-group .summary-row {
  width: 100%; }
  .submit-container .summary-group .summary-row.four-columns .summary-cell {
    width: 20%;
    display: inline-block; }
  .submit-container .summary-group .summary-row.two-columns .summary-cell {
    width: 40%;
    display: inline-block; }
  .submit-container .summary-group .summary-row.three-columns .summary-cell {
    width: 100%;
    display: inline-block; }
    @media (min-width: 992px) {
      .submit-container .summary-group .summary-row.three-columns .summary-cell {
        width: 50%; } }
    @media (min-width: 1200px) {
      .submit-container .summary-group .summary-row.three-columns .summary-cell {
        width: 33%; } }

.submit-container .summary-group span {
  font-size: 18px; }
  .submit-container .summary-group span.label {
    font-weight: bold;
    padding-right: 20px;
    font-weight: 500; }


.body-container {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 5px;
  margin-top: 20px;
  padding: 0 10px 0 15px; }
  .body-container a {
    text-decoration: none;
    color: #162B4C;
    border-bottom: 2px solid transparent; }
    .body-container a:hover, .body-container a.active {
      border-bottom: solid 2px #00b3c8; }
  .body-container .elevation0 {
    padding-left: 0; }

