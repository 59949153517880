
@import '../../../src/styles/_variable.scss';
@import '../../../src/styles/_mixin.scss';

.textfield-container {
  margin-top: 10px;
  margin-bottom: 10px;
  vertical-align: top;
  width: 100%;
}
