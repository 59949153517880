/**
 * Borrowed from uikit
 * https://github.com/govau/uikit/blob/1d26de4ec1d4838bfe716555a177029e26c3d0c8/packages/skip-link/src/sass/_module.scss
 */

$uikit-font-system: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$body-text-colour: #333333;
$base-leading: 1.6;
$white:#ffffff;

@mixin uikit-sronly() {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
  color: black;
  background-color: $white;
}

//-----------------------------------------------------------------------------
// Skip-to links
//-----------------------------------------------------------------------------
.uikit-skip-link {
  font-family: $uikit-font-system;
  color: $body-text-colour;
  line-height: $base-leading;
}

.uikit-skip-link__link {
  @include uikit-sronly;

  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: absolute;
    width: auto;
    font-size: 21px;
    top: uikit-space( 0.5 );
    left: uikit-space( 0.5 );
    padding: uikit-space( 0.25 ) uikit-space( 0.5 );
    color: $body-text-colour;
    background-color: $white;
    text-decoration: none;
    z-index: 1;
  }
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.is-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  background-color: #ffffff !important;
  color: #333333 !important; }

.hidden {
  display: none; }
