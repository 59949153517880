@import '../../../src/styles/_variable.scss';
@import '../../../src/styles/_mixin.scss';

.menuBurgerWrapper-container {
  padding-bottom: $lg-spacing;
  padding-left: 0;
  margin-top: 0;
  display: inline-block;
  width: 100%;
}
