@import '../../../src/styles/_variable.scss';
@import '../../../src/styles/_mixin.scss';

.address-container {
  @include tablet-portrait {
    width:95%;
  }
  .manualAddress {
    font-size: 18px;
    margin-left: $md-spacing;
    display: inline-block;
    text-decoration: none;
  }
  .enterManually-container {
    &.half-area {
      label {
        width: 100%;
        & + div {
          width: 100%;
        }
      }
    }
  }
}
