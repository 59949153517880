
@import '../../../src/styles/_variable.scss';
@import '../../../src/styles/_mixin.scss';

.submit-container {
  h2 {
  }
  h4 {
    font-size: 22px;
    font-size: 500;
    margin-bottom: 15px;

    a {
      border-bottom: solid 2px #00b3c8;
    }
  }
  .summary-group {
    .summary-row {
      width: 100%;
      &.four-columns {
        .summary-cell {
          width: 20%;
          display: inline-block;
        }
      }
      &.two-columns {
        .summary-cell {
          width: 40%;
          display: inline-block;
        }
      }
      &.three-columns {
        .summary-cell {
          width: 100%;
          display: inline-block;
          @include tablet-wide {
            width: 50%;
          }
          @include desktop {
            width: 33%;
          }
        }
      }
    }
    span {
      font-size: 18px;
      &.label {
        font-weight: bold;
        padding-right: 20px;
        font-weight: 500;
      }
    }
  }
}
